<template>
  <div class="w-full max-w-screen-xl relative mx-auto py-8 rounded-lg relative">
    <EditJobFormSteps
      v-if="$usesFeature('candidate-wizard')"
    />
  </div>
</template>

<script>
import EditJobFormSteps from '@components/Jobs/EditJobFormSteps'

export default {
  components: {
    EditJobFormSteps
  },

  created() {
    if (!this.haveJobsLoaded) {
      this.$store.dispatch('jobs/getJobs')
    }
  }
}
</script>
