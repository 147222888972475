<template>
  <div
    ref="jobRole"
  >
    <label
      class="block font-medium text-sm text-gray-700"
      for="searchJobRoles"
    >
      <p class="mb-1">{{ label }}</p>
    </label>

    <template v-if="!chosenJobRole">
      <div class="flex items-center relative">
        <input
          id="searchJobRoles"
          ref="dropdown-location"
          v-model="query"
          type="search"
          class="form-input w-full rounded-lg"
          @keydown.esc="close"
          @keydown.up="highlightPrev"
          @keydown.down="highlightNext"
          @keydown.enter.prevent="disableDropdown ? $emit('keydownEnter', query) : selectHighlighted"
          @keydown.tab="close"
        >
        <svg
          v-if="fetching"
          class="animate-spin mx-3 h-5 w-5 text-gray-600 absolute right-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>
    </template>
    <div
      v-else
      class="form-input w-full rounded-lg truncate"
      @click="resetSelection"
    >
      {{ chosenJobRole && chosenJobRole.name ? chosenJobRole.name : chosenJobRole }}
    </div>

    <div
      v-if="!disableDropdown"
      class="relative overflow-visible"
    >
      <ul
        v-if="jobRoles.length && !chosenJobRole && query"
        ref="options"
        class="absolute text-sm ml-auto bg-white shadow-lg max-h-60 rounded-lg w-full z-40 border border-gray-300 overflow-y-auto"
        style="top: 0.5rem;"
      >
        <li
          v-for="(result, index) in jobRoles"
          :key="index"
          tabindex="-1"
          :class="['text-gray-900 cursor-default select-none relative py-2 px-4 flex items-center', { 'bg-gray-200': index === highlightedIndex }]"
          @click="fetchJobRole(result.slug)"
        >
          <span class="font-normal block truncate">
            {{ result.name }}
          </span>
        </li>
      </ul>
    </div>
    <ErrorsInline
      v-if="v.$anyError"
      class="relative"
    >
      <span v-if="!v.required">Please choose a role</span>
    </ErrorsInline>
  </div>
</template>

<script>
import ErrorsInline from '@components/ErrorsInline'
import jobsApi from '@api/jobs'
import candidateWizardApi from '@api/candidateWizard'
import debounce from 'lodash.debounce'
import { getLocalStorage } from '@utils/localStorage'

export default {
  components: {
    ErrorsInline
  },

  props: {
    v: {
      type: Object,
      default: null
    },
    disableDropdown: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Job title or role'
    }
  },

  data() {
    return {
      query: '',
      jobRoles: [],
      chosenJobRole: null,
      highlightedIndex: 0,
      fetching: false
    }
  },

  computed: {
    endpoint() {
      return getLocalStorage('candidateWizard.uuid') ? candidateWizardApi : jobsApi
    }
  },

  watch: {
    query(query) {
      if (query) {
        this.fetching = true

        this.searchJobRoles(query)
        this.$emit('selected', query)
      } else {
        this.$emit('selected', null)
      }
    },

    chosenJobRole(val) {
      if (val) {
        this.$emit('selected', this.chosenJobRole)
      }
    }
  },

  created() {
    this.searchJobRoles = debounce(this.searchJobRoles, 500)

    this.$nextTick(() => {
      this.chosenJobRole = Array.isArray(this.v.$model) ? null : this.v.$model
    })
  },

  methods: {
    resetSelection() {
      this.chosenJobRole = null
      this.$nextTick(() => this.$refs[`dropdown-location`].focus())
    },

    /**
     * Search job roles
     */
    searchJobRoles(query) {
      this.fetching = true

      return this.endpoint.searchJobRoles(query)
        .then(jobRoles => {
          this.fetching = false
          this.jobRoles = jobRoles
          this.$emit('searchedRoles', this.jobRoles)
        })
        .catch(error => {
          this.fetching = false
          console.error(error)
        })
    },

    /**
     * Fetch job role
     */
    fetchJobRole(jobSlug) {
      return this.endpoint.fetchJobRole(jobSlug)
        .then(jobRole => {
          this.chosenJobRole = jobRole
        })
        .catch(error => {
          console.error(error)
        })
    },

    select(option) {
      this.chosenJobRole = option
      this.highlightedIndex = 0
    },

    selectHighlighted() {
      this.fetchJobRole(this.jobRoles[this.highlightedIndex].slug)
    },

    scrollToHighlighted() {
      if (this.$refs.options.children[this.highlightedIndex]) {
        this.$refs.options.children[this.highlightedIndex].scrollIntoView({
          block: 'nearest'
        })
      }
    },

    highlight(index) {
      this.highlightedIndex = index

      if (this.highlightedIndex < 0) {
        this.highlightedIndex = this.jobRoles.length - 1
      }

      if (this.highlightedIndex > this.jobRoles.length - 1) {
        this.highlightedIndex = 0
      }

      this.scrollToHighlighted()
    },

    highlightPrev() {
      this.highlight(this.highlightedIndex - 1)
    },

    highlightNext() {
      this.highlight(this.highlightedIndex + 1)
    },

    close() {
      if (!this.jobRoles.length || this.chosenJobRole) {
        return
      }
      this.chosenJobRole = ''
      this.jobRoles = []
    }
  }
}
</script>
