<!--
  This compontent is similar to @components/ToggleSwitchEvent,
  but it does not contain any flexing.
-->

<template>
  <label
    class="flex items-center"
    :for="`switch-${id}`"
  >
    <div class="relative">
      <input
        :id="`switch-${id}`"
        type="checkbox"
        class="hidden"
        @change="toggle()"
      />
      <div
        class="w-10 h-4 bg-gray-400 rounded-full shadow-inner mt-1"
      ></div>
      <div
        :class="['absolute w-6 h-6 bg-gray-300 rounded-full shadow inset-0 left-0 transition duration-300 ease-in-out', { 'transform translate-x-full bg-secondary': isChecked }]"
      ></div>
    </div>
    <div
      :class="['ml-4 text-gray-600 text-sm transition duration-300 ease-in-out', { 'text-secondary': isChecked }]"
    >
      <slot />
    </div>
  </label>
</template>

<script>
export default {
  props: {
    id: {
      type: [String, Number],
      default: 'switch'
    },
    isChecked: {
      type: Boolean,
      required: true,
      default: false
    }
  },

  methods: {
    toggle() {
      this.$emit('toggle')
    }
  }
}
</script>
