<template>
  <div
    ref="location"
  >
    <div class="grid grid-cols-4 gap-6 text-sm">
      <div class="col-span-4">
        <label
          class="block font-medium text-gray-700"
          for="searchLocations"
        >
          <p class="mb-1">Search towns &amp; cities</p>
        </label>
        <input
          v-if="!chosenLocation"
          id="searchLocations"
          ref="dropdown-location"
          v-model="searchedLocation"
          type="search"
          class="form-input w-full rounded-lg"
          @keydown.esc="close"
          @keydown.up="highlightPrev"
          @keydown.down="highlightNext"
          @keydown.enter.prevent="selectHighlighted"
          @keydown.tab="close"
        >
        <div
          v-else
          class="form-input w-full rounded-lg truncate"
          @click="resetSelection"
        >
          {{ chosenLocation.formattedAddress }}
        </div>
        <div class="relative overflow-visible">
          <ul
            v-if="searchResults.length && !chosenLocation && searchedLocation"
            ref="options"
            class="absolute text-sm ml-auto bg-white shadow-lg max-h-60 rounded-lg w-full z-40 border border-gray-300 overflow-y-auto"
            style="top: 0.5rem;"
          >
            <li
              v-for="(result, index) in searchResults"
              :key="index"
              tabindex="-1"
              :class="['text-gray-900 cursor-default select-none relative py-2 px-4 flex items-center', { 'bg-gray-200': index === highlightedIndex }]"
              @click="chooseLocation(result)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 text-gray-500 stroke-current mr-2"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
              <span class="font-normal block truncate">
                {{ result.description }}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <ErrorsInline
        v-if="v.$anyError"
        class="relative"
      >
        <span v-if="!v.required">Please choose a location</span>
      </ErrorsInline>
    </div>
  </div>
</template>

<script>
import ErrorsInline from '@components/ErrorsInline'

export default {
  components: {
    ErrorsInline
  },

  props: {
    v: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      searchedLocation: '',
      searchResults: [],
      chosenLocation: null,
      highlightedIndex: 0,
      googleApiKey: process.env.VUE_APP_GOOGLE_API_KEY,

      service: null,
      geocoder: null
    }
  },

  watch: {
    searchedLocation(newValue) {
      if (newValue) {
        this.service.getPlacePredictions({
          input: this.searchedLocation,
          types: ['(cities)']

        }, this.displaySuggestions)
      }
    },

    chosenLocation(val) {
      if (val) {
        this.$emit('selected', this.chosenLocation)
      }
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.chosenLocation = this.v.$model
    })
  },

  beforeDestroy() {
    // Stop: 'You have included the Google Maps API multiple times on this page.' error
    window.google = {}
  },

  page() {
    return {
      script: [{
        src: `https://maps.googleapis.com/maps/api/js?key=${this.googleApiKey}&libraries=places`,
        async: true,
        defer: true,
        callback: () => this.mapsInit()
      }]
    }
  },

  methods: {
    resetSelection() {
      this.chosenLocation = null
      this.$nextTick(() => this.$refs[`dropdown-location`].focus())
    },

    mapsInit() {
      this.service = new window.google.maps.places.AutocompleteService()
      this.geocoder = new window.google.maps.Geocoder()
    },

    displaySuggestions(predictions, status) {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        this.searchResults = []
        return
      }
      this.searchResults = predictions
    },

    geocodeAddress(location) {
      return new Promise((resolve, reject) => this.geocoder
        .geocode({
          'placeId': location.place_id
        }, (results, status) => status === 'OK'
          ? resolve({
            countryCode: this.extractCountry(results[0].address_components),
            formattedAddress: results[0].formatted_address,
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng()
          }) : reject(new Error('Cannot find address'))
        ))
    },

    async chooseLocation(location) {
      try {
        this.chosenLocation = await this.geocodeAddress(location)
      } catch (error) {
        console.error(error)
      }
    },

    extractCountry(addrComponents) {
      for (var i = 0; i < addrComponents.length; i++) {
        if (addrComponents[i].types[0] === 'country') {
          return addrComponents[i].short_name
        }
        if (addrComponents[i].types.length === 2) {
          if (addrComponents[i].types[0] === 'political') {
            return addrComponents[i].short_name
          }
        }
      }
      return false
    },

    select(option) {
      this.chosenLocation = option
      this.highlightedIndex = 0
    },

    selectHighlighted() {
      this.chooseLocation(this.searchResults[this.highlightedIndex])
    },

    scrollToHighlighted() {
      if (this.$refs.options.children[this.highlightedIndex]) {
        this.$refs.options.children[this.highlightedIndex].scrollIntoView({
          block: 'nearest'
        })
      }
    },

    highlight(index) {
      this.highlightedIndex = index

      if (this.highlightedIndex < 0) {
        this.highlightedIndex = this.searchResults.length - 1
      }

      if (this.highlightedIndex > this.searchResults.length - 1) {
        this.highlightedIndex = 0
      }

      this.scrollToHighlighted()
    },

    highlightPrev() {
      this.highlight(this.highlightedIndex - 1)
    },

    highlightNext() {
      this.highlight(this.highlightedIndex + 1)
    },

    close() {
      if (!this.searchResults.length || this.chosenLocation) {
        return
      }
      this.chosenLocation = ''
      this.searchResults = []
    }
  }
}
</script>
