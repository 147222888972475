<template>
  <ul class="-mt-4">
    <li
      v-for="(field, fieldIdx) in profileFields"
      :key="fieldIdx"
      class="flex items-center justify-between py-4 border-b border-dashed"
    >
      <span>{{ profileFieldsMap(field) }}</span>
      <div class="flex items-center">
        <transition
          enter-active-class="transition-all duration-500 ease-out"
          leave-active-class="transition-all duration-150 ease-in"
          enter-class="opacity-0"
          leave-class="opacity-100"
          enter-to-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <span
            v-if="hidden.includes(field)"
            class="inline-flex items-center px-2 py-0.5 rounded text-xs bg-gray-100 text-gray-800 mr-6"
          >
            Do not request
          </span>
        </transition>
        <ToggleSwitch
          :id="fieldIdx"
          :is-checked="!hidden.includes(field)"
          @toggle="toggle(field)"
        />
      </div>
    </li>
  </ul>
</template>

<script>
import ToggleSwitch from '@components/FormElements/ToggleSwitch'

export default {
  components: {
    ToggleSwitch
  },

  props: {
    hiddenProfileFields: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      profileFields: [
        'photo',
        'workHistory',
        'educationHistory',
        'roleExperience',
        'skills',
        'location',
        'companyPreference',
        'employmentStatus'
      ],
      hidden: []
    }
  },

  created() {
    this.hidden = Object.assign([], this.hiddenProfileFields)
  },

  methods: {
    /**
     * Maps Profile field slugs to display name
     */
    profileFieldsMap(field) {
      const fields = {
        photo: 'Photo',
        workHistory: 'Work History',
        educationHistory: 'Education History',
        roleExperience: 'Role Experience',
        skills: 'Skills',
        location: 'Location',
        companyPreference: 'Company Preferences',
        employmentStatus: 'Employment Status'
      }
      return fields[field]
    },

    toggle(field) {
      if (!this.hidden.includes(field)) {
        this.hidden.push(field)
      } else {
        this.hidden = this.hidden.filter(profileField => profileField !== field)
      }

      this.$emit('hiddenProfileFields', this.hidden)
    }
  }
}
</script>
