<template>
  <div>
    <div class="flex items-center justify-between px-6">
      <header class="h-20 pb-8">
        <h1
          v-if="$route.params.id"
          class="text-2xl leading-tight"
        >
          Editing {{ formResponses.role }}
        </h1>
        <h1
          v-else
          class="text-2xl leading-tight"
        >
          <span v-if="!$usesFeature('jobs-labels')">Create assessment</span>
          <span v-else-if="$usesFeature('jobs-labels')">Create job</span>
        </h1>
      </header>

      <div class="flex items-center mb-8 md:w-72">
        <div class="w-full mr-2 bg-gray-400 rounded">
          <div
            class="h-2 text-xs leading-none text-center text-white rounded bg-secondary"
            :style="`width: ${parseInt(step / totalSteps * 100)}%`"
          ></div>
        </div>
        <div class="flex-shrink-0 text-sm font-medium text-gray-600">
          Step {{ step }} of {{ totalSteps }}
        </div>
      </div>
    </div>

    <div
      v-if="haveProductsLoaded && hasJobsLimit && step === 1"
      class="px-6"
    >
      <div
        v-if="jobsCount > 0"
        class="px-4 py-5 mb-6 bg-white rounded-lg shadow"
      >
        <Notice
          variant="info"
        >
          <h3
            v-if="assessmentJobsLimit > 0"
            class="font-medium texts"
          >
            You’re using {{ jobsCount }} of {{ assessmentJobsLimit }}
            {{ assessmentJobsLimit > 1 ? 'jobs' : 'job' }}
          </h3>
          <h3
            v-else
            class="text-sm font-medium"
          >
            You’re using {{ jobsCount }}
            {{ jobsCount > 1 ? 'jobs' : 'job' }}
          </h3>
          <div
            v-if="!canAddJob"
            class="mt-2 text-sm duration-150 ease-in-out"
          >
            You’ll need to
            <router-link
              :to="{ name: 'settings-plan' }"
              class="font-medium underline hover:text-secondary"
            >
              upgrade your plan
            </router-link>
            before you can add another job
          </div>
        </Notice>
      </div>
    </div>

    <form
      class="flex flex-col px-6 py-6 space-y-8 bg-gray-100"
      novalidate
      style="min-height: 525px;"
      @submit.prevent
    >
      <template v-if="step === 1">
        <section>
          <div class="md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-1">
              <div class="px-4 sm:px-0">
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  Basic information
                </h3>
                <p class="mt-1 text-sm text-gray-600">
                  Add a job title and assign a deadline.
                </p>
              </div>
            </div>
            <div class="mt-5 md:mt-0 md:col-span-2">
              <div
                class="rounded-lg shadow"
              >
                <div class="px-4 py-5 space-y-6 bg-white rounded-lg sm:p-6">
                  <SearchJobRoles
                    :v="$v.formResponses.role"
                    @selected="handleRoleSelection($event)"
                  />

                  <div>
                    <label class="block mb-1 text-sm font-medium text-gray-700">
                      Deadline (optional)
                    </label>
                    <DatePicker
                      :value="formResponses.deadline"
                      @selected="formResponses.deadline = $event"
                      @clear="formResponses.deadline = null"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div class="md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-1">
              <div class="px-4 sm:px-0">
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  Location
                </h3>
                <p class="mt-1 text-sm text-gray-600">
                  Choose a primary location for this job.
                </p>
              </div>
            </div>
            <div class="mt-5 md:mt-0 md:col-span-2">
              <div
                class="rounded-lg shadow"
              >
                <div class="px-4 py-5 space-y-6 bg-white rounded-lg sm:p-6">
                  <div class="md:flex md:justify-between md:items-center">
                    <SearchGoogleLocations
                      class="flex-1"
                      :v="$v.formResponses.location"
                      @selected="formResponses.location = $event"
                    />
                    <label class="flex items-center mt-5 text-gray-700 md:ml-6">
                      <input
                        v-model="$v.formResponses.remote.$model"
                        type="checkbox"
                        class="rounded form-checkbox"
                      >
                      <span class="ml-2 text-sm font-medium">
                        Fully remote
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div class="md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-1">
              <div class="px-4 sm:px-0">
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  Job details
                </h3>
                <p class="mt-1 text-sm text-gray-600">
                  Include specific details about the job here.
                </p>
              </div>
            </div>
            <div class="mt-5 md:mt-0 md:col-span-2">
              <div
                class="rounded-lg shadow sm:overflow-hidden"
              >
                <div class="px-4 py-5 space-y-6 bg-white rounded-lg sm:p-6">
                  <div>
                    <label
                      for="description"
                      class="block mb-1 text-sm font-medium text-gray-700"
                    >
                      Description
                    </label>

                    <textarea
                      id="description"
                      v-model="$v.formResponses.description.$model"
                      class="w-full rounded-lg resize-none form-textarea focus:border-gray-800 focus:shadow-none"
                      rows="6"
                    />
                  </div>

                  <div>
                    <label
                      for="requirements"
                      class="block mb-1 text-sm font-medium text-gray-700"
                    >
                      Requirements
                    </label>

                    <textarea
                      id="requirements"
                      v-model="$v.formResponses.requirements.$model"
                      class="w-full rounded-lg resize-none form-textarea focus:border-gray-800 focus:shadow-none"
                      rows="6"
                    />
                  </div>

                  <EditJobBenefits v-model="$v.formResponses.benefits.$model" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div class="md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-1">
              <div class="px-4 sm:px-0">
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  Employment details
                </h3>
                <p class="mt-1 text-sm text-gray-600">
                  Add additional information and extra requirements here.
                </p>
              </div>
            </div>
            <div class="mt-5 md:mt-0 md:col-span-2">
              <div
                class="rounded-lg shadow sm:overflow-hidden"
              >
                <div class="grid px-4 py-5 space-y-6 bg-white rounded-lg sm:p-6 md:grid-cols-3 md:gap-8 md:space-y-0">
                  <div>
                    <label
                      for="employmentType"
                      class="block mb-1 text-sm font-medium text-gray-700"
                    >
                      Employment Type
                    </label>
                    <div class="mt-1">
                      <select
                        id="employmentType"
                        v-model="$v.formResponses.employmentType.$model"
                        name="employmentType"
                        class="w-full text-sm rounded-lg form-select "
                      >
                        <option
                          v-for="(type, typeIdx) in employmentTypes"
                          :key="typeIdx"
                          :value="type"
                        >
                          {{ type }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div>
                    <label
                      for="experience"
                      class="block mb-1 text-sm font-medium text-gray-700"
                    >
                      Experience
                    </label>
                    <div class="mt-1">
                      <select
                        id="experience"
                        v-model="$v.formResponses.experience.$model"
                        name="experience"
                        class="w-full text-sm rounded-lg form-select "
                      >
                        <option
                          v-for="(level, levelIdx) in experience"
                          :key="levelIdx"
                          :value="level"
                        >
                          {{ level }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div>
                    <label
                      for="education"
                      class="block mb-1 text-sm font-medium text-gray-700"
                    >
                      Education
                    </label>
                    <div class="mt-1">
                      <select
                        id="education"
                        v-model="$v.formResponses.education.$model"
                        name="education"
                        class="w-full text-sm rounded-lg form-select "
                      >
                        <option
                          v-for="(classification, classificationIdx) in education"
                          :key="classificationIdx"
                          :value="classification"
                        >
                          {{ classification }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div class="md:grid md:grid-cols-3 md:gap-6">
          <div class="md:col-span-1">
            <div class="px-4 sm:px-0">
              <h3 class="text-lg font-medium leading-6 text-gray-900">
                Annual salary
              </h3>
              <p class="mt-1 text-sm text-gray-600">
                Enter the salary range for this job here.
              </p>
            </div>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2">
            <div
              class="divide-y rounded-lg shadow sm:overflow-hidden divide divide-dashed"
            >
              <div class="px-4 py-5 bg-white rounded-lg sm:p-6">
                <div class="grid space-y-6 md:grid-cols-3 md:gap-8 md:space-y-0">
                  <label class="relative block text-gray-700">
                    <span class="text-sm font-medium">From</span>
                    <input
                      v-model="$v.formResponses.salary.from.$model"
                      type="number"
                      min="0"
                      step="1000"
                      class="w-full mt-1 rounded-lg form-input"
                    >
                  </label>
                  <label class="relative block text-gray-700">
                    <span class="text-sm font-medium">To</span>
                    <input
                      v-model="$v.formResponses.salary.to.$model"
                      type="number"
                      min="0"
                      step="1000"
                      class="w-full mt-1 rounded-lg form-input"
                    >
                  </label>
                  <div>
                    <label
                      for="currencyCode"
                      class="block mb-1 text-sm font-medium text-gray-700"
                    >
                      Currency
                    </label>
                    <div class="mt-1">
                      <select
                        id="currencyCode"
                        v-model="$v.formResponses.salary.currencyCode.$model"
                        name="currencyCode"
                        class="w-full text-sm rounded-lg form-select "
                      >
                        <option
                          v-for="currency in currencies"
                          :key="currency.code"
                          :value="currency.code"
                        >
                          {{ currency.name }} ({{ currency.code }})
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <section v-if="step === 2">
        <div class="md:grid md:grid-cols-3 md:gap-6">
          <div class="md:col-span-1">
            <div class="px-4 sm:px-0">
              <h3 class="text-lg font-medium leading-6 text-gray-900">
                Test Suites
              </h3>
              <p class="mt-1 text-sm text-gray-600">
                Choose which assessments you would like to send to your candidates.
              </p>
            </div>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2">
            <div
              class="rounded-lg shadow sm:overflow-hidden"
            >
              <div class="px-4 py-5 space-y-6 bg-white sm:p-6">
                <p class="mb-4 text-sm">
                  Choose tests from our curated packages:
                </p>

                <div v-if="clientExamsReady">
                  <div class="space-y-8">
                    <div
                      v-for="examSuite in examSuites"
                      :key="examSuite.slug"
                    >
                      <h3 class="block mb-4 text-sm font-medium text-gray-700">
                        {{ examSuite.name }}
                      </h3>
                      <ul class="grid gap-4 overflow-hidden md:grid-cols-2">
                        <li
                          v-for="exam in examSuiteExams(examSuite)"
                          :key="exam.slug"
                        >
                          <label class="flex items-center px-2 py-3 border border-gray-300 rounded-lg">
                            <input
                              v-model="formResponses.exams"
                              :value="exam.slug"
                              type="checkbox"
                              class="form-checkbox"
                            >
                            <span class="ml-2">{{ exam.name }}</span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="my-8">
                    <template>
                      <h3 class="mb-4 text-sm font-medium">
                        Custom tests
                      </h3>
                    </template>
                    <router-link
                      class="block text-sm text-right underline"
                      :to="{ name: 'test-builder' }"
                    >
                      Create a custom test
                    </router-link>

                    <ul
                      v-if="customExams && customExams.length"
                      class="grid gap-4 overflow-hidden md:grid-cols-2"
                    >
                      <li
                        v-for="exam in customExams"
                        :key="exam.uuid"
                      >
                        <label class="flex items-center w-full px-2 py-3 border border-gray-300 rounded-lg">
                          <input
                            v-model="formResponses.customExams"
                            :value="exam.uuid"
                            type="checkbox"
                            class="form-checkbox"
                          >
                          <span class="ml-2">{{ exam.name }}</span>
                        </label>
                      </li>
                    </ul>
                  </div>

                  <div>
                    <template v-if="bespokeExams && bespokeExams.length">
                      <h3 class="mb-4 text-sm font-medium">
                        Bespoke tests
                      </h3>
                    </template>
                    <ul class="grid gap-4 overflow-hidden md:grid-cols-2">
                      <li
                        v-for="bespokeExam in bespokeExams"
                        :key="bespokeExam.slug"
                      >
                        <label class="flex items-center w-full px-2 py-3 border border-gray-300 rounded-lg">
                          <input
                            v-model="formResponses.exams"
                            :value="bespokeExam.slug"
                            type="checkbox"
                            class="form-checkbox"
                          >
                          <span class="ml-2">{{ bespokeExam.name }}</span>
                        </label>
                      </li>
                    </ul>
                  </div>

                  <div
                    v-if="clientExamsLoading"
                    class="py-3"
                    style="min-height: 247px;"
                  >
                    <Loader />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section v-if="step === 3">
        <div class="md:grid md:grid-cols-3 md:gap-6">
          <div class="md:col-span-1">
            <div class="px-4 sm:px-0">
              <h3 class="text-lg font-medium leading-6 text-gray-900">
                Personal questions
              </h3>
              <p class="mt-1 text-sm text-gray-600">
                Want to know more? Ask candidates here.
              </p>
            </div>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2">
            <div
              class="rounded-lg shadow sm:overflow-hidden"
            >
              <div class="px-4 py-5 bg-white sm:p-6">
                <h3 class="text-lg font-medium">
                  Standard questions
                </h3>
                <p class="mt-3 mb-6 text-sm">
                  Choose which information you would like your candidate to submit.
                </p>

                <ToggleProfileFields
                  class="mb-12"
                  :hidden-profile-fields="formResponses.hiddenProfileFields"
                  @hiddenProfileFields="formResponses.hiddenProfileFields = $event"
                />

                <h3 class="text-lg font-medium">
                  Custom questions
                </h3>
                <p class="mt-3 mb-6 text-sm">
                  Create your own questions for your candidates here.
                </p>
                <ul
                  v-if="formResponses.personalQuestions.length"
                  class="mb-6 space-y-6"
                >
                  <li
                    v-for="(question, questionIdx) in formResponses.personalQuestions"
                    :key="questionIdx"
                  >
                    <label
                      class="sr-only"
                      :for="`question-${questionIdx}`"
                    >
                      <span class="text-sm font-medium">Question {{ questionIdx }}</span>
                    </label>

                    <div class="relative flex min-w-0 cursor-pointer">
                      <div class="flex items-center justify-center flex-shrink-0 w-16 text-xl font-medium text-white rounded-l bg-secondary">
                        <span>{{ questionIdx + 1 }}</span>
                      </div>
                      <input
                        :id="`question-${questionIdx}`"
                        v-model="$v.formResponses.personalQuestions.$each[questionIdx].body.$model"
                        placeholder="Your question..."
                        type="text"
                        class="w-full px-4 py-4 text-sm font-medium rounded-tr-lg rounded-br-lg form-input"
                        @keydown.enter.prevent="addPersonalQuestion"
                      >

                      <TooltipButton
                        class="flex flex-col justify-center mx-4 duration-150 hover:text-red-500"
                        @click="deletePersonalQuestion(questionIdx)"
                      >
                        <template v-slot:content>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-4 h-4 text-gray-600"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </template>
                        <template v-slot:tooltip>
                          Delete question
                        </template>
                      </TooltipButton>
                    </div>
                    <ErrorsInline v-if="hasPersonalQuestionError">
                      <span v-if="!$v.formResponses.personalQuestions.$each[questionIdx].body.required">This field is required</span>
                    </ErrorsInline>
                  </li>
                </ul>

                <button
                  class="w-full px-4 py-4 duration-150 ease-out border-2 border-gray-200 border-dashed rounded shadow-sm focus:outline-none focus:border-gray-400 hover:border-gray-400 hover:text-gray-600"
                  @click="addPersonalQuestion"
                >
                  Add question
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        v-if="errorMessage"
        class="relative"
      >
        <ErrorsInline class="right-0">
          {{ errorMessage }}
        </ErrorsInline>
      </div>

      <div class="flex items-center justify-between mt-8 md:grid md:grid-cols-3 md:gap-6">
        <div class="col-start-2">
          <BaseButton
            v-if="showPreviousButton"
            style="min-width: 132px;"
            variant="ghost"
            @click="previousStep"
          >
            <Icon
              view-box="0 0 284.929 284.929"
              class="w-3 h-3 mr-2"
            >
              <Chevron />
            </Icon>
            Previous
          </BaseButton>
        </div>
        <div class="col-start-3 ml-auto">
          <template>
            <BaseButton
              v-if="showNextButton"
              style="min-width: 132px;"
              class="ml-auto border border-secondary"
              :disabled="!canSubmitForm || !canAddJob"
              @click="nextStep"
            >
              Next
              <template slot="iconRight">
                <Icon
                  view-box="0 0 284.929 284.929"
                  class="w-3 h-3 ml-2 text-white transform rotate-180"
                >
                  <Chevron />
                </Icon>
              </template>
            </BaseButton>
          </template>

          <template v-if="step === 3 && !$route.params.id">
            <BaseButton
              style="min-width: 132px;"
              class="ml-auto border border-secondary"
              :loading="isProcessing"
              :disabled="!canSubmitForm || !canAddJob"
              @click="submit"
            >
              Finish
            </BaseButton>
          </template>

          <template v-if="step === 3 && $route.params.id">
            <BaseButton
              style="min-width: 132px;"
              class="ml-auto border border-secondary"
              :loading="isProcessing"
              :disabled="!canSubmitForm"
              @click="update"
            >
              Finish
            </BaseButton>
          </template>
        </div>
      </div>
    </form>

    <Modal
      :open="modalOpen"
      @close="modalOpen = false"
    >
      <div
        class="bg-white border-b-2"
      >
        <div class="flex justify-between px-6 pb-6">
          <h3 class="text-lg font-medium">
            Job Created
          </h3>
          <button
            class="text-gray-500 focus:outline-none"
            @click="closeModalAndGoToJob"
          >
            <Icon
              width="16px"
              height="16px"
              view-box="0 0 14 14"
              class="transform rotate-45"
            >
              <Plus />
            </Icon>
          </button>
        </div>
      </div>

      <InviteCandidatesModalContent
        :job="createdJob"
        class="p-6"
        is-modal
      />
    </Modal>
  </div>
</template>

<script>
import ErrorsInline from '@components/ErrorsInline'
import Notice from '@components/Notice'
import Loader from '@components/Loader'
import Icon from '@components/Icons/Icon'
import Chevron from '@components/Icons/Chevron'
import Plus from '@components/Icons/Plus'
import InviteCandidatesModalContent from '@components/Candidates/InviteCandidatesModalContent'
import EditJobBenefits from '@components/Jobs/EditJobBenefits'
import SearchGoogleLocations from '@components/SearchGoogleLocations'
import SearchJobRoles from '@components/Jobs/SearchJobRoles'
import TooltipButton from '@components/TooltipButton'
import Modal from '@components/Modal'
import ToggleProfileFields from '@components/Jobs/ToggleProfileFields'

import DatePicker from '@components/DatePicker'

import currencies from '@data/currencies'
import classifications from '@groupResources/lists/educationClassifications'

import { required, minLength } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import { mapGetters } from 'vuex'
import { format, parseISO } from 'date-fns'

export default {
  components: {
    ErrorsInline,
    Notice,
    Loader,
    Icon,
    Chevron,
    Plus,
    DatePicker,
    InviteCandidatesModalContent,
    EditJobBenefits,
    SearchGoogleLocations,
    SearchJobRoles,
    TooltipButton,
    Modal,
    ToggleProfileFields
  },

  mixins: [validationMixin],

  props: {
    job: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      currencies,
      format,
      parseISO,
      isProcessing: false,
      errorMessage: null,
      hasPersonalQuestionError: false,
      modalOpen: false,

      formResponses: {
        role: null,
        deadline: null,
        location: null,
        remote: false,
        description: null,
        requirements: null,
        benefits: [],
        employmentType: null,
        experience: null,
        education: null,
        salary: {
          from: null,
          to: null,
          currencyCode: 'GBP'
        },
        exams: [],
        customExams: [],
        personalQuestions: [],
        hiddenProfileFields: []
      },

      employmentTypes: [
        'Permanent',
        'Contract',
        'Internship'
      ],

      experience: [
        'Not Applicable',
        'Internship',
        'Junior level',
        'Associate',
        'Director',
        'Executive'
      ],

      education: classifications,

      jobRoles: [],

      step: 1,
      totalSteps: 3,

      createdJob: null
    }
  },

  computed: {
    ...mapGetters({
      clientExamsReady: 'clientExams/isReady',
      clientExamsLoading: 'clientExams/isLoading',
      exams: 'clientExams/exams',
      examSuites: 'clientExams/examSuites',
      customExams: 'clientExams/customExams',
      currentSubscription: 'subscriptions/currentSubscription',
      hasUnlimitedJobs: 'organisations/hasUnlimitedJobs',
      haveSubscriptionsLoaded: 'subscriptions/haveSubscriptionsLoaded',
      activeJobs: 'jobs/activeJobs',
      haveProductsLoaded: 'products/haveProductsLoaded',
      products: 'products/products'
    }),

    /**
     * @return {Boolean}
     */
    canSubmitForm() {
      return !this.$v.formResponses.$anyError
    },

    /**
     * @return {Object}
     */
    currentProduct() {
      if (!this.currentSubscription) {
        return
      }
      return this.products.find(product => {
        return product.plans.find(plan => {
          return plan.stripeId === this.currentSubscription.stripeId
        })
      })
    },

    /**
     * Can we add a job? Logic for allowing if a job can be added if using
     * a jobs limit on a plan
     *
     * @return {Boolean}
     */
    canAddJob() {
      if (!this.$usesFeature('jobs-limit')) {
        return true
      }
      if (!this.haveProductsLoaded) {
        return false // Loading
      }
      if (this.hasUnlimitedJobs) {
        return true
      }
      // Editing an already-created job
      if (this.$route.params.id) {
        return true
      }

      return this.jobsCount < this.assessmentJobsLimit
    },

    /**
     * @return {Boolean}
     */
    hasJobsLimit() {
      return this.$usesFeature('jobs-limit')
    },

    /**
     * @return {Number}
     */
    assessmentJobsLimit() {
      if (!this.haveProductsLoaded) {
        return null
      }
      if (this.hasUnlimitedJobs) {
        return 0
      }
      if (!this.currentProduct) {
        return 1
      }
      if (!this.currentProduct.restrictions || !this.currentProduct.restrictions.active_jobs) {
        return 1
      }

      return parseInt(this.currentProduct.restrictions.active_jobs.jobs)
    },

    /**
     * @return {Number}
     */
    jobsCount() {
      if (!this.activeJobs) {
        return 0
      }
      return this.activeJobs.length
    },

    /**
     * @return {Boolean}
     */
    showPreviousButton() {
      return this.step !== 1
    },

    /**
     * @return {Boolean}
     */
    showNextButton() {
      return this.step !== 3
    },

    /**
     * Return exams with no exam suite
     *
     * @return {Array}
     */
    bespokeExams() {
      return this.examSuiteExams()
    },

    /**
     * @return {Object}
     */
    jobToSubmit() {
      return {
        name: this.formResponses.role,
        deadline: this.formResponses.deadline ? this.format(this.formResponses.deadline, 'yyyy-MM-dd') : null,
        location: this.formResponses.location,
        remote: this.formResponses.remote,
        description: this.formResponses.description,
        requirements: this.formResponses.requirements,
        employmentType: this.formResponses.employmentType,
        experience: this.formResponses.experience,
        education: this.formResponses.education,
        salary: this.formResponses.salary,
        benefits: this.formResponses.benefits,
        exams: this.formResponses.exams,
        customExams: this.formResponses.customExams,
        personalQuestions: this.formResponses.personalQuestions,
        hiddenProfileFields: this.formResponses.hiddenProfileFields
      }
    }
  },

  validations: {
    formResponses: {
      role: {
        required
      },
      deadline: {},
      location: {},
      remote: {},
      description: {},
      requirements: {},
      benefits: {},
      employmentType: {},
      experience: {},
      education: {},
      salary: {
        from: {},
        to: {},
        currencyCode: {}
      },
      exams: {
        minLength: minLength(1)
      },
      customExams: {},
      personalQuestions: {
        $each: {
          uuid: {},
          body: {
            required
          }
        }
      }
    }
  },

  watch: {
    formResponses: {
      handler(data) {
        if (data.role) {
          this.errorMessage = ''
        }
      },
      deep: true
    }
  },

  mounted() {
    this.getProducts()
    this.getExams()

    if (this.job) {
      this.formResponses = {
        ...this.job,
        deadline: this.job.deadline ? this.parseISO(this.job.deadline) : null,
        role: this.job.name,
        exams: this.job.exams ? this.job.exams.map(exam => exam.slug) : null,
        customExams: this.job.customExams ? this.job.customExams.map(customExam => customExam.uuid) : [],
        personalQuestions: this.job.personalQuestions.length ? this.job.personalQuestions : []
      }
    }
  },

  methods: {
    /**
     * Fetch the plans if we need to get them
     */
    getProducts() {
      if (this.haveProductsLoaded) {
        this.getSubscriptions()
        return
      }
      this.$store.dispatch('products/getProducts')
        .then(() => {
          this.getSubscriptions()
        })
    },

    /**
     * Fetch the subscriptions
     */
    getSubscriptions() {
      if (this.haveSubscriptionsLoaded) {
        return
      }
      this.$store.dispatch('subscriptions/getSubscriptions')
    },

    /**
     * Fetch exams
     */
    getExams() {
      this.$store.dispatch('clientExams/getClientExams')
    },

    /**
     * Return exams including in this exam suite
     *
     * @param {string} examSuite
     * @return {Array}
     */
    examSuiteExams(examSuite) {
      if (!examSuite) {
        // If no examSuite is provided, return exams that don’t have an exam
        // suite, sometimes called ‘Bespoke’
        return this.exams.filter(exam => {
          if (exam.custom) {
            return false
          }

          if (!exam.examSuites) {
            return true
          }

          return exam.examSuites.length === 0
        })
      }

      return this.exams.filter(exam => {
        if (exam.custom) {
          return false
        }

        const examSuiteSlugs = exam.examSuites.map(examSuite => examSuite.slug)

        return examSuiteSlugs.includes(examSuite.slug)
      })
    },

    /**
     * Handle Role Selection
     */
    handleRoleSelection(jobRole) {
      // Reset the fields
      if (jobRole === null) {
        this.formResponses.role = null
        this.formResponses.description = null
        this.formResponses.requirements = null
      }
      // The jobRole has been chosen from our list
      if (jobRole && jobRole.name) {
        this.formResponses.role = jobRole.name
        this.formResponses.description = jobRole.description
        this.formResponses.requirements = jobRole.requirements.join('\n\n')
      } else {
        // The jobRole is custom
        this.formResponses.role = jobRole
      }
    },

    /**
     * Go to previous step
     */
    previousStep() {
      if (this.step === 1) {
        return
      }
      this.step--
    },

    /**
     * Go to next step
     */
    nextStep() {
      if (!this.formResponses.role) {
        this.errorMessage = 'Please add a job title or role'
        return
      }
      this.step++
    },

    /**
     * Add Personal Question
     */
    addPersonalQuestion() {
      this.formResponses.personalQuestions.push({
        uuid: null,
        body: ''
      })
      this.hasPersonalQuestionError = false
    },

    /**
     * Deletes Personal Question
     */
    deletePersonalQuestion(questionIdx) {
      this.formResponses.personalQuestions = this.formResponses.personalQuestions.filter((option, index) => index !== questionIdx)
    },

    /**
     * Closes Modal and moves to job page
     */
    closeModalAndGoToJob() {
      this.modalOpen = false
      this.$router.push({
        name: 'client-assessments-show',
        params: { id: this.createdJob.uuid }
      })
    },

    /**
     * Create Job
     */
    submit() {
      if (this.isProcessing) {
        return
      }

      if (!this.formResponses.exams.length && !this.formResponses.customExams.length) {
        this.errorMessage = 'Please select at least one test'
        return
      }

      // Validate Personal Questions
      this.$v.formResponses.personalQuestions.$touch()
      this.hasPersonalQuestionError = this.$v.formResponses.personalQuestions.$invalid
      if (this.hasPersonalQuestionError) {
        this.isProcessing = false
        return
      }

      this.isProcessing = true
      this.errorMessage = null

      this.$store.dispatch('jobs/create', this.jobToSubmit)
        .then((job) => {
          this.createdJob = job
          this.modalOpen = true
          this.isProcessing = false
        })
        .catch(error => {
          this.isProcessing = false
          if (error.response && error.response.data && error.response.data.errors) {
            if (Array.isArray(error.response.data.errors)) {
              this.errorMessage = error.response.data.errors[0]
            }
          }
        })
    },

    /**
     * Update Job
     */
    update() {
      if (this.isProcessing) {
        return
      }

      this.isProcessing = true

      // Validate Personal Questions
      this.$v.formResponses.personalQuestions.$touch()
      this.hasPersonalQuestionError = this.$v.formResponses.personalQuestions.$invalid
      if (this.hasPersonalQuestionError) {
        this.isProcessing = false
        return
      }

      this.$store.dispatch('jobs/update', { id: this.job.uuid, job: this.jobToSubmit })
        .then(() => {
          this.$router.go(-1)
          this.isProcessing = false
        })
        .catch(error => {
          this.isProcessing = false
          if (error.response && error.response.data && error.response.data.errors) {
            if (Array.isArray(error.response.data.errors)) {
              this.errorMessage = error.response.data.errors[0]
            }
          }
        })
    }
  }
}
</script>
