<template>
  <div class="grid md:grid-cols-2 gap-8 divide divide-y md:divide-x md:divide-y-0 divide-dashed">
    <div>
      <label
        for="addBenefit"
        class="block font-medium text-sm mb-1 text-gray-700"
      >
        Add a benefit
      </label>

      <div class="flex">
        <input
          id="addBenefit"
          v-model="benefit"
          type="text"
          class="form-input w-full"
          @keyup.enter.prevent="addBenefit()"
        >
        <button
          class="relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none"
          @click="addBenefit()"
        >
          <span>Add</span>
        </button>
      </div>

      <ul class="text-sm flex flex-wrap -mx-2 mt-4">
        <li
          v-for="(suggestedBenefit, suggestedBenefitIdx) in suggestedBenefits"
          :key="suggestedBenefitIdx"
          class="p-2"
        >
          <button
            type="button"
            class="inline-flex items-center px-3 py-0.5 rounded-lg text-sm font-medium bg-gray-200 text-gray-700 hover:bg-gray-400 focus:outline-none duration-150 ease-out"
            :class="[benefits.includes(suggestedBenefit) ? 'pointer-events-none opacity-50' : 'pointer-events-auto opacity-100']"
            @click="addSuggestedBenefit(suggestedBenefit)"
          >
            <span class="sr-only">Select {{ suggestedBenefit }}</span>
            <span class="mr-2"> {{ suggestedBenefit }}</span>
            <svg
              class="h-2 w-2 transform rotate-45"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 8 8"
            >
              <path
                stroke-linecap="round"
                stroke-width="1.5"
                d="M1 1l6 6m0-6L1 7"
              />
            </svg>
          </button>
        </li>
      </ul>
    </div>
    <div class="pt-8 md:pt-0 md:pl-8">
      <p class="text-sm font-medium mb-1 text-gray-700">
        Benefits for this job
      </p>
      <p
        v-if="!benefits.length"
        class="text-xs text-gray-600"
      >
        No benefits chosen
      </p>
      <ul class="text-sm flex flex-wrap -mx-2" >
        <li
          v-for="(benefit, benefitIdx) in benefits"
          :key="benefitIdx"
          class="p-2"
        >
          <button
            type="button"
            class="inline-flex items-center px-3 py-0.5 rounded-lg text-sm font-medium bg-secondary text-white hover:bg-secondary-400 focus:outline-none duration-150 ease-out"
            @click="removeBenefit(benefitIdx)"
          >
            <span class="sr-only">Remove {{ benefit }}</span>
            <span class="mr-2"> {{ benefit }}</span>
            <svg
              class="h-2 w-2"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 8 8"
            >
              <path
                stroke-linecap="round"
                stroke-width="1.5"
                d="M1 1l6 6m0-6L1 7"
              />
            </svg>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      benefit: '',
      benefits: [],
      suggestedBenefits: [
        'Health insurance',
        'Life insurance',
        'Pension plan',
        'Performance bonus',
        'Flexible working',
        'Gym membership',
        'Season ticket loan',
        'Free snacks',
        'Professional development',
        'Work from home'
      ]
    }
  },

  mounted() {
    this.$nextTick(() => {
      if (this.value.length) {
        this.benefits = this.value
      }
    })
  },

  methods: {
    addBenefit() {
      if (!this.benefit) {
        return
      }
      this.benefits.push(this.benefit)
      this.$emit('input', this.benefits)
      this.benefit = ''
    },

    addSuggestedBenefit(benefit) {
      this.benefits.push(benefit)
    },

    removeBenefit(index) {
      this.benefits.splice(index, 1)
      this.$emit('input', this.benefits)
    }
  }
}
</script>
